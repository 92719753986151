import { useState } from "react"
import CustomHelmet from "@/components/CustomHelmet/CustomHelmet"
import { navigate } from "gatsby"
import { createUserUtils } from "@/utils"
import Title from "@/components/Title"
import Typography from "@/components/Typography"
import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"
import loadable from "@loadable/component"
import preview from "../images/CoursePreviewWhite.png"
const ReactPlayer = loadable(() => import("react-player"))

import styled from "styled-components"
const HeaderSectionRoot = styled.section`
  position: relative;
  min-height: 100vh;
  background-color: #141517;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 strong {
    font-weight: 600;
  }
`
const MyContainer = styled(Container)`
  flex: 1;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: column-reverse;

  .text-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .title {
      max-width: 630px;
      font-size: 3.2rem;
      line-height: 97.84%;
      letter-spacing: -0.035em;
    }
    .desc {
      max-width: 527px;
      margin: 3.3rem 0 5rem;
      opacity: 0.7;
      font-size: 1.4rem;
      line-height: 139.34%;
      letter-spacing: -0.035em;
    }
  }

  .video-content {
    flex: 1;
    position: relative;
    margin-bottom: 5rem;
    padding-top: 56.25%;
    .frame {
      position: absolute;
      top: 0;
      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    .text-content {
      margin-right: 2rem;
      .title {
        font-size: 25px;
      }
      .desc {
        margin: 1.5rem 0 3rem;
        font-size: 12px;
      }
    }
    .video-content {
      padding-top: 26%;
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 1025px) {
    padding-top: 13rem;
    padding-bottom: 13rem;
    .text-content {
      .title {
        font-size: 3.6rem;
        line-height: 100%;
      }
      .desc {
        margin: 3rem 0 5rem;
        font-size: 1.6rem;
        line-height: 120%;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .text-content {
      .title {
        font-size: 4.5rem;
        line-height: 100%;
      }
      .desc {
        margin: 4rem 0 6rem;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .text-content {
      .title {
        font-size: 5.4rem;
      }
      .desc {
        margin: 6rem 0 8rem;
      }
    }
  }
`
const BtnBuy = styled(ButtonBase)`
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  &.loading {
    background-color: #c4c4c4;
  }
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.primary};
  @media only screen and (min-width: 576px) {
    width: 284px;
  }
  @media only screen and (min-width: 768px) {
    width: 250px;
    height: 40px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) {
    width: 284px;
    height: 49px;
    font-size: 16px;
  }
`

const FreeLesson = ({ location: { state } }) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState(false)

  const buyCourse = async () => {
    if (state?.email) {
      setLoading(true)
      const { key, ...restValues } = state

      const Auth = (await import("@/aws/AmplifyAuthConfigure")).default
      await Auth.currentSession()
        .then(async session => {
          const jwtToken = session.getAccessToken().getJwtToken()
          if (jwtToken) await Auth.signOut()
        })
        .catch(error => {})

      try {
        const {
          data: { createUserLead },
        } = await createUserUtils({ ...restValues })
        const res = JSON.parse(createUserLead)
        if (res.statusCode !== 200) throw `${res.body}`

        setLoading(false)
        window.location.replace(res.body)
      } catch (error) {
        setLoading(false)
        setErr(error)
      }
    } else navigate("/#ref")
  }

  return (
    <>
      <CustomHelmet title="Get RON certified with Ronary Course" />
      <HeaderSectionRoot>
        <MyContainer>
          <div className="text-content">
            <Title className="title">
              Get RON certified with <span>Ronary Course</span>
            </Title>

            <Typography as="p" color="white" className="desc">
              This course is specially designed by notaries for notaries. The
              first step towards providing remote online notarizations has never
              been easier.
            </Typography>
            <BtnBuy
              onClick={buyCourse}
              disabled={loading}
              className={loading ? "loading" : ""}
            >
              Buy online course - ${state?.coursePrice || 49}
            </BtnBuy>
          </div>
          <div className="video-content">
            <ReactPlayer
              light={preview}
              playing
              controls
              url="https://player.vimeo.com/video/665664150?h=84d8fd5fb9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              playsinline={true}
              width="100%"
              height="100%"
              className="frame"
            />
          </div>
        </MyContainer>
      </HeaderSectionRoot>
    </>
  )
}

export default FreeLesson
