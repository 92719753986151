import {
  CREATE_USER_LEAD,
  GET_STATUS_OF_PAYMENT,
  GET_PRESENTATION_ACCESS,
  RECORD_INVESTOR_FEEDBACK,
} from "@/graphql/mutations"
import { API } from "@/aws/AmplifyAuthConfigure"

// export const leadUtils = async (id, values) => {
//   let userId
//   if (id) {
//     userId = await graphqlClient
//       .request(GET_LEAD, { id })
//       .then(res => res.leadCourse.id)
//       .catch(() => false)
//   }
//   if (userId) {
//     return graphqlClient
//       .request(UPDATE_LEAD, {
//         input: { where: { id: userId }, data: values },
//       })
//       .then(res => res.updateLeadCourse.leadCourse.id)
//   }
//   return graphqlClient
//     .request(CREATE_LEAD, {
//       input: { data: values },
//     })
//     .then(res => res.createLeadCourse.leadCourse.id)
// }

export const createUserUtils = async config => {
  const {
    promocode = "",
    coursePrice = 49,
    freeLesson = false,
    ...rest
  } = config

  const utm = {}
  const siteLink = window.location.href
  window.location.search
    .replace("?", "")
    .split("&")
    .map(str => {
      if (str) {
        const elem = str.split("=")
        if (elem) utm[elem[0]] = elem[1]
      }
    })
  try {
    return await API.graphql({
      query: CREATE_USER_LEAD,
      variables: {
        input: {
          ...rest,
          promocode,
          coursePrice,
          freeLesson,
          utm: JSON.stringify(utm),
          siteLink,
        },
      },
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const checkPayment = async email => {
  try {
    return await API.graphql({
      query: GET_STATUS_OF_PAYMENT,
      variables: {
        input: {
          email,
        },
      },
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

// UTILS FUNCTION FOR PRESENTATION
export const getPresentationAccess = async code => {
  try {
    return await API.graphql({
      query: GET_PRESENTATION_ACCESS,
      variables: {
        input: {
          code,
        },
      },
    })
  } catch (error) {
    return Promise.reject("Something went wrong please try again later")
  }
}
// set investor feedback
export const recordInvestorFeedback = async ({ code, feedback }) => {
  try {
    return await API.graphql({
      query: RECORD_INVESTOR_FEEDBACK,
      variables: {
        input: {
          code,
          feedback,
        },
      },
    })
  } catch (error) {
    return Promise.reject("Something went wrong please try again later")
  }
}
