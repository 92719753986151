import React from "react"
import { Helmet } from "react-helmet"

const CustomHelmet = ({ children, title, canonical = false }) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}
      {children}
    </Helmet>
  )
}

export default CustomHelmet
